import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import SVG from 'react-inlinesvg';

import DataTestimonials from '../data/testimonials.json';
import Layout from '../components/Layout';
import Button from '../components/Button';
import Markdown from '../components/Markdown';
import ReferenceSwiper from '../components/ReferenceSwiper';
import Experience from '../components/sections/Experience';

import BackgroundClientsSrc from '../img/svg/pageHome/conference_bg.svg';
import IllustrationA from '../img/svg/pageBusiness/illustration_1.svg';
import IllustrationB from '../img/svg/pageBusiness/illustration_2.svg';
import ImgBgHero from '../img/svg/pageBusiness/hero_bg.svg';
import ImgBgHero2 from '../img/svg/pageBusiness/hero_2_bg.svg';
import ImageStarsSrc from '../img/svg/stars.svg';

import BackgroundTopSrc from '../img/svg/pageHome/solutions_top_bg.svg';
import BackgroundBottomSrc from '../img/svg/pageHome/solutions_bottom_bg.svg';

import CardStatic from '../components/CardStatic';

export const BusinessPageTemplate = ({
  hero,
  hero2,
  ourSolutions,
  how,
  projects,
  opinions,
  experience
}) => (
  <div className="Business">
    <section className="Business__feature">
      <div className="Layout__background">
        <SVG src={ImageStarsSrc} className="Layout__stars" />
      </div>
      
      <div className="Business__feature__background">
        <div className="Business__feature__background__space"></div>
        <SVG
          src={ImgBgHero}
          className="Business__feature__background-image"
        />
      </div>  
        
      <div className="Business__container Business__feature__container">
        <div className="Business__feature__content">
          <h1 className="Business__feature__title">{hero.title}</h1>
          <p className="Business__feature__text">
            {hero.text}
          </p>
        </div>

        <div className="Business__feature__illustration">
          <SVG 
            src={IllustrationA} 
            className="Business__feature__image" 
            alt="servers" 
          />
        </div>
      </div>
    </section>

    <section className="Business__feature Business__feature--reverse">
    <div className="Business__feature__background">
        <div className="Business__feature__background__space"></div>
        <SVG
          src={ImgBgHero2}
          className="Business__feature__background-image"
        />
      </div>  

      <div className="Business__container Business__feature__container">
        <div className="Business__feature__content">
          <h2 className="Business__feature__title">
            {hero2.title}
          </h2>
          <p className="Business__feature__text">
            {hero2.text}
          </p>
        </div>

        <div className="Business__feature__illustration">
          <SVG 
            src={IllustrationB} 
            className="Business__feature__image" 
            alt="servers" 
          />
        </div>
      </div>
    </section>

    <section className="Business__solutions" id="sectionSolutions">
      <SVG
        src={BackgroundTopSrc}
        uniquifyIDs={false}
        className="Business__solutions__background Business__solutions__background--top"
      />

      <div className="Business__solutions__container">
        <div className="Business__solutions__content">
          <h2 className="Business__solutions__title">
            {ourSolutions.heading}
          </h2>

          <p className="Business__solutions__text">
            {ourSolutions.text}
          </p>
        </div>

        <ul className="Business__solutions__items">
          {ourSolutions && ourSolutions.items &&
            ourSolutions.items.map((item, i) => (
              <CardStatic
                key={i}
                tagName="li"
                className="Business__solutions__item"
                iconSrc={item.icon && item.icon.publicURL}
                title={item.name}
                text={item.text}
              >
                <Markdown
                  content={item.text}
                  className="Business__solutions__item__list"
                />
              </CardStatic>
            ))}
        </ul>
      </div>

      <SVG
        src={BackgroundBottomSrc}
        uniquifyIDs={false}
        className="Business__solutions__background Business__solutions__background--bottom"
      />
    </section>

    <section className="Business__projects">
      <div className="Business__projects__container">
        <div className="Business__projects__content">
          <h2 className="Business__projects__title">
            {projects.heading}
          </h2>

          <p className="Business__projects__text">
            {projects.text}
          </p>
        </div>

        <ul className="Business__projects__items">
          {projects && projects.items &&
            projects.items.map((item, i) => (
              <CardStatic
                key={i}
                tagName="li"
                className="Business__projects__item"
              >
                <div className="Business__projects__item__cont">
                  <div>
                    <img src={item.logo && item.logo.publicURL} alt="" />
                    {item.text}
                  </div>
                  {item.slug &&
                    <Button
                      className="Business__projects__item__btn" 
                      variants={['primary-outline']}
                      to={item.slug}
                      id={'business_project_btn_' + i}
                    >
                      Their story
                    </Button>
                  }
                </div>
              </CardStatic>             
            ))}
        </ul>
      </div>
    </section>

    <div className="Business__how">
      <div className="Business__how__background">
        <div className="Business__container">
          <div className="Business__how__content">
            <h2 className="Business__how__title">
              {how.heading}
            </h2>
            <p className="Business__how__text">
              {how.text}
            </p>
          </div>

          <div class="Business__how__steps">
            {how && how.items &&
              how.items.map((item, i) => (
                <div class="Business__how__step-wrapper">
                  <div class="Business__how__step-content">
                    <img src={item.icon && item.icon.publicURL} alt="" />
                    <h3>{item.name}</h3>
                    <p className="">{item.text}</p>
                  </div>
                </div>
              ))
            }
          </div>
        </div>
      </div>
    </div>

    <section className="Business__testimonials">
      <div className="Business__testimonials__background">
        <SVG
          src={BackgroundClientsSrc}
          uniquifyIDs={false}
          className="Business__testimonials__background-image"
        />
      </div>
      <div className="Business__testimonials__container">
        <h2 className="Business__testimonials__title">{opinions.heading}</h2>
        <div className="Business__testimonials__text">
          {opinions.text}
        </div>
        <ReferenceSwiper data={DataTestimonials.workshops} />
      </div>
    </section>

    <Experience 
      heading={experience.heading}
      items={experience.items}
    />
  </div>
);

const TrainingPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout variants={['backgrounded']} meta={frontmatter.meta}>
      <BusinessPageTemplate {...frontmatter} />
    </Layout>
  );
};

TrainingPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default TrainingPage;

export const trainingPageQuery = graphql`
  query BusinessPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "big-data-for-business-page" } }) {
      frontmatter {
        meta {
          title
          description
          image {
            publicURL
            name
          }
        }

        hero {
          title
          text
        }

        hero2 {
          title
          text
        }

        ourSolutions {
          heading
          text
          items {
            icon {
              publicURL
              name
            }
            name
            text
          }
        }

        projects {
          heading
          text
          items {
            logo {
              publicURL
              name
            }
            text
            slug
          }
        }

        experience {
          heading
          items {
            text
            title
            icon {
              publicURL
              name
            }
            slug
          }
        }

        how {
          heading
          text
          items {
            icon {
              publicURL
              name
            }
            name
            text
          }
        }

        opinions {
          heading
          text
        }
      }
    }
  }
`;
